@import "src/styles/lib";

.Whitelist {
  &__item-name{
    font-size: rem(16);
    color: #111827;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 1.5;
    font-size: $proxima-nova;
  }
  &__header {
    margin-top: rem(24);
    margin-bottom: rem(50);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: rem(22);
    font-weight: bold;
    margin: 0;
    color: #111827;
    letter-spacing: 0.4px;
  }
  &__header-button {
    max-width: rem(152);
    background-color: #0d99ff;
    color: $white;
    padding: rem(20);
    margin-left: rem(21);
    letter-spacing: 0.4px;
    &:hover {
      background: darken(#0d99ff, 15%);
    }
  }
  &__container {
    background: $white;
    border-radius: rem(16);
    min-height: rem(650);
    margin-bottom: rem(40);
    padding-bottom: rem(40);
  }
  &__no-content {
    text-align: center;
    margin: rem(180) auto;
    font-size: rem(24);
    color: $primary-color;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
  &__search-wrapper {
     padding: rem(24);
  }
  &__item-icon {
    width: rem(20);
    height: rem(20);
    margin-left: rem(5);
    &:hover {
      svg {
        color: darken(#fd6a6a, 10%);
        cursor: pointer
      }
    }
    svg {
      color: #fd6a6a;
    }
  }
  &__log-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;
    padding: rem(24) 0 rem(24) rem(30);
    border-bottom: 1px solid #eeeff2;
    border-top: 1px solid #eeeff2;
    div {
      display: flex;
      align-items: center;
    }
    span {
      font-size: rem(16);
      color: #718096;
      line-height: rem(24);
      letter-spacing: 0.2px;
      margin: 0;
    }
  }
  &__header-icon {
    width: rem(16);
    height: rem(16);
    margin-left: rem(5);
    &:hover {
      cursor: pointer;

      svg {
        color: darken(#718096, 20%);
      }
    }
    svg {
      color: #718096;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(24) rem(50) rem(24) rem(30);
    border-bottom: 1px solid #eeeff2;
    &:hover {
      background: #fafafa;
      cursor: pointer;
    }
  }
}

@include mobile {
  .Whitelist {
    &__item {
      padding: rem(24) rem(24) rem(24) rem(30); 
    }
  }
}