@import "src/styles/lib";

.AddEmail {
   width: rem(420);
   padding: rem(12);
   &__icon {
    width: rem(12);
    height: rem(12);
    display: block;
    margin-left: auto;
    cursor: pointer;
   }
   &__title {
    font-size: rem(24);
    line-height: rem(31);
    font-weight: 700;
    color: #111827;
    margin-bottom: rem(42);
   }
 
   &__login {
    font-size: rem(14);
    line-height: rem(22.4);
    font-weight: 700;
    letter-spacing: 0.2px;
    color: $primary-color;
    margin-bottom: rem(40);
    text-decoration: none;
    display: block;
    cursor: pointer;
   }
   &__input-group-container{
    position: relative;
   }
   &__form-control {
    margin-bottom: rem(32);
   }
}