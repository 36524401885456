@import "src/styles/lib";

.LoginForm {
    &__input-group-container{
        position: relative;
    }
    &__btn {
        margin-top: rem(12);
    }
    &__invalid-feedback {
        position: absolute;
        bottom: rem(5);
        left: rem(5);
        font-size: rem(12);
        color: #f06548;
    }
    &__form-control {
        margin-bottom: rem(24);
    }
}

@include mobile {
    .LoginForm {
        &__forgot-password {
            font-size: 12px;
        } 
        &__btn {
            margin-top: 5px;
        }
    }
}