@import "src/styles/lib";

.Layout {
  display: flex;
  width: 100%;

  //Container--------------

  &__container {
    margin-left: rem(70);
    width: 100%;
    &--open {
      //width: calc(100% - 256px);
      margin-left: rem(256);
    }
    &--mobile {
      width: 100%;
      margin-left: 0;
    }
  }
  &__routes-container {
    padding: rem(30);
    margin-top: rem(96);
    width: 100%;
  }
}

@include mobile {
  .Layout {
    &__routes-container {
      padding: 30px 10px;
    }
  }
}
