@import "src/styles/lib";

.Analytics {
  &__title {
    font-size: rem(22);
    font-weight: bold;
    margin: rem(24) 0 rem(50) 0;
    color: #111827;
    letter-spacing: 0.4px;
  }
  &__container {
    background: $white;
    border-radius: rem(16);
    min-height: rem(650);
    margin-bottom: rem(40);
    padding-bottom: rem(40);
  }
  &__no-content {
    text-align: center;
    margin: rem(180) auto;
    font-size: rem(24);
    color: $primary-color;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
  &__search-wrapper {
     padding: rem(24);
  }
  &__log-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5%;
    padding: rem(24) 0 rem(24) rem(30);
    border-bottom: 1px solid #eeeff2;
    border-top: 1px solid #eeeff2;
    div {
      display: flex;
      align-items: center;
    }
    span {
      font-size: rem(16);
      color: #718096;
      line-height: rem(24);
      letter-spacing: 0.2px;
      margin: 0;
    }
  }
  &__header-icon {
    width: rem(16);
    height: rem(16);
    margin-left: rem(5);
    &:hover {
      cursor: pointer;

      svg {
        color: darken(#718096, 20%);
      }
    }
    svg {
      color: #718096;
    }
  }
  &__image-wrapper {
    width: 30px;
    height: 30px;
    margin-right: rem(10);
    border-radius: 50%;
  }
  &__image {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50%;
  
  }
  &__organization-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-left: rem(10);
  }
  &__organization {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5%;
    padding: rem(24) 0 rem(24) rem(30);
    border-bottom: 1px solid #eeeff2;
    align-items: center;
    &:hover {
      background: #fafafa;
      cursor: pointer;
    }
  }
  &__organization-name-wrapper {
    display: flex;
    align-items: center;
  }
  &__organization-name, &__organization-category, &__organization-address {
    font-size: rem(16);
    color: #111827;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 1.5;
    text-transform: capitalize;
    font-size: $proxima-nova;
  }
  &__organization-status {
    font-size: rem(16);
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 1.5;
    text-transform: capitalize;
    font-size: $proxima-nova;
    &--true {
      color: $green;
    }
    &--false {
      color: $error;
    }
  }
}

@include mobile {
  .Analytics {
    &__organization-name, &__organization-category, &__organization-address, &__organization-status {
      font-size: rem(13);
      text-align: center;
    }
    &__log-header {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 4%;
      padding: rem(24) 0 rem(24) rem(10);
      text-align: center;
      span {
        font-size: rem(13);
      }
    }
    &__organization {
      padding: rem(24) rem(5);
      align-items: center;
    }
    &__organization-name-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    &__image-wrapper {
      margin-right: 0;
    }
    &__organization-icon {
      position: absolute;
      right: -5px;
      top: 5px;
      width: 20px;
      height: 20px;
      object-fit: cover;
    //  margin-left: rem(10);
    }
  }
}