@import "src/styles/lib";

.HeaderProfile {
    height: 100%;
    position: relative;
    font-family: $proxima-nova;
  
    &__head {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative;
      cursor: pointer;
      &:hover {
        cursor: pointer;
      }
    }
  
    &__name {
      margin: 0;
      font-size: rem(16);
      line-height: rem(24);
      font-weight: 700;
      letter-spacing: 0.3px;
      color: #111827;
    }
  
    &__role {
      margin: 0;
      font-size: rem(14);
      font-weight: 400;
      line-height: 19.2px;
      color: #718096;
      letter-spacing: 0.3px;
      transition: all 200ms ease-in-out;
      &:hover {
        color: #111827;
      }
    }
  
    &__body {
      background-color: $white;
      padding: rem(12);
      box-shadow: 10px 15px 50px 0px rgba(113,128,150, 0.08);
      font-size: rem(14);
      position: absolute;
      color: #111827;
      border-radius: rem(16);
      min-width: 100%;
      width: rem(200);
      right: 0;
      top: rem(85);
    }
    &__icon {
      width: 24px;
      height: 24px;
      margin-right: rem(12);
    }


   &__icon--active svg path {
      fill: #111827;
   }
  
    &__logout {
      padding: rem(12);
      white-space: nowrap;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: rem(8);
      color: #FD6A6A;
      position: relative;
      transition: all 200ms ease-in-out;
      &:hover {
        background-color: #f9fafb;
        color: darken(#FD6A6A, 10%);
      }
    }
  
    &__profile {
      padding: rem(12);
      white-space: nowrap;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: rem(8);
      color: #718096;
      transition: all 200ms ease-in-out;
      &:hover {
        background-color: #f9fafb;
        color: #111827;
      }
    }
    &__profile:hover  &__icon svg path   {
      stroke: #111827;
    }
    &__logout:hover  &__icon svg path   {
      stroke: darken(#FD6A6A, 10%);
    }
    &__switch {
      position: absolute;
      left: 190px;
    }
  }
  