@import "src/styles/lib";

.OrganizationAnalytics {
  &__title {
    font-size: rem(22);
    font-weight: bold;
    color: #111827;
    text-transform: capitalize;
    letter-spacing: 0.4px;
  }
  &__container {
    background: $white;
    border-radius: rem(16);
    min-height: rem(650);
    margin-bottom: rem(40);
  }
  &__header {
    margin-top: rem(24);
    margin-bottom: rem(50);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__header-layout {
    background-color: $white;
    border-radius: rem(12);
    padding: rem(8);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-content: center;
    height: rem(56);
  }
  &__layout-item {
    color: #a0aec0;
    padding: rem(11);
    text-align: center;
    &:hover {
        cursor: pointer;
        color: #0d99ff;
        background-color: #e4f4ff;
        border-radius: rem(8);
    }
    &--active {
        color: #0d99ff;
        background-color: #e4f4ff;
        border-radius: rem(8); 
    }
  }
  &__header-buttons {
    display: flex;
    align-items: center;
  }
  &__header-text {
      font-size: rem(14);
      color: #ADB5BD;
      margin-right: rem(10);
  }
 &__selectbox2, &__selectbox3  {
    margin-right: rem(10);
    padding: 0;
    min-width: rem(80);
  }
  &__selectbox-header {
    padding: rem(4) rem(3) rem(3) rem(8);
  }
  &__export-button {
    max-width: rem(129);
    background-color: #0d99ff;
    color: $white;
    padding: rem(8) rem(12);
    margin-left: rem(21);
    font-size: rem(14);
    letter-spacing: 0.6px;
    text-decoration: none;
    &:hover {
      background: darken(#0d99ff, 15%);
    }
  }
  &__no-content {
    text-align: center;
    margin: rem(180) auto;
    font-size: rem(24);
    color: $primary-color;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
  &__search-wrapper {
    padding: rem(16);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__log-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(40);
    padding: rem(24) 0 rem(24) rem(30);
    border-bottom: 1px solid #eeeff2;
    border-top: 1px solid #eeeff2;
    div {
      display: flex;
      align-items: center;
    }
    span {
      font-size: rem(16);
      color: #718096;
      line-height: rem(24);
      letter-spacing: 0.2px;
      margin: 0;
    }
  }
  &__header-icon {
    width: rem(16);
    height: rem(16);
    margin-left: rem(5);
    &:hover {
      cursor: pointer;

      svg {
        color: darken(#718096, 20%);
      }
    }
    svg {
      color: #718096;
    }
  }
 
  &__organization-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-left: rem(10);
  }
  &__organization {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(40);
    align-items: center;
    padding: rem(24) 0 rem(24) rem(30);
    border-bottom: 1px solid #eeeff2;
    &:hover {
      background: #fafafa;
      cursor: pointer
    }
  }
  &__organization-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__organization-name, &__organization-category, &__organization-address {
    font-size: rem(16);
    color: #111827;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 1.5;
    text-transform: capitalize;
    font-family: $proxima-nova;

  }
  &__organization-status {
    font-size: rem(16);
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 1.5;
    text-transform: capitalize;
    font-size: $proxima-nova;
    &--true {
      color: $green;
    }
    &--false {
      color: $error;
    }
  }
  &__count-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(40);
    align-items: center;
    padding: rem(24) 0 rem(24) rem(30);
    color: #718096;
  }
  &__count-sum {
    margin-left: rem(-50);
  }
}
@include mobile {
  .OrganizationAnalytics {
    &__header {
      flex-direction: column;
      margin-bottom: rem(32);
    }
    &__title {
      margin-bottom: rem(20);
    }
    &__header-text {
      margin-bottom: rem(10);
    }
    &__search-wrapper {
      align-items: flex-end;
    }

    &__header-buttons {
      flex-direction: column;
    }
    &__count-sum {
      margin-left: rem(-5);
    }
  }
}
