@import "src/styles/lib";

.Sidebar {
  display: flex;
  flex-direction: column;
  width: rem(70);
  position: fixed;
  min-height: 100vh;
  max-height: 100%;
  height: auto;
  z-index: 501;
  box-shadow: 0 -3px 2px #38414a26;
  background-color: $white;
  &__open {
    width: rem(256);
  }
  &__mobile {
    z-index: 501;
    visibility: hidden;
    &--open {
      visibility: visible;
    }
  }

  &__logo {
    cursor: pointer;
    height: 33px;
    margin-right: 10px;

    &-container {
      position: fixed;
      left: rem(22);
      min-height: rem(96);
      display: flex;
      align-items: center;
    }
  }

  &__icon-container {
    transform: rotate(180deg);
    margin-top: -8px;
    margin-left: -30px;
    &--open {
      transform: rotate(0deg);
      margin-left: 50px;
      margin-top: 5px;
    }
  }

  &__icon svg {
    width: rem(82);
    height: rem(82);
    cursor: pointer;
  }

  &__content {
    height: 100%;
    padding-bottom: rem(20);
    position: fixed;
    top: rem(110);
    left: 0;
    &--open {
      left: 23px;
    }

    &-scroll {
      overflow-y: scroll;
      margin-right: rem(3);

      &::-webkit-scrollbar {
        width: rem(6);
        min-height: rem(10);
      }

      &::-webkit-scrollbar-thumb {
        background: #838fb9;
        border-radius: rem(5);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: lighten(#838fb9, 10%);
      }

      &-hide {
        overflow-y: scroll;
        margin-right: rem(3);

        &::-webkit-scrollbar {
          width: rem(6);
          min-height: rem(10);
        }

        &::-webkit-scrollbar-thumb {
          background: #838fb9;
          border-radius: rem(5);
        }

        &::-webkit-scrollbar-thumb:hover {
          background: lighten(#838fb9, 10%);
        }
      }
    }
  }
}

@include mobile {
  .Sidebar {
    position: fixed;
    height: 100%;
    &__logo-container {
      position: static;
      margin-left: rem(22);
    }
    &__content {
      position: static;
      padding-left: 2.5rem;
      margin-top: 14px;
    }
  }
}
