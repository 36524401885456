@import "src/styles/lib";

.Header {
  background-color: $white;
  height: rem(96);
  position: fixed;
  top: 0;
  left: rem(70);
  right: 0;
  padding: 0 rem(30);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  &__open {
    left: rem(256);
  }
  &__mobile {
    z-index: 500;
    left: 0;
  }

  &__shadow {
    box-shadow: 0 rem(1) rem(2) #38414a26;
  }

  &__left {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__right {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@include tablet {
  .Header {
    padding: 0 10px;
  }
}

@include mobile {
  .Header {
    padding: 0 10px;
  }
}